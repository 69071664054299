import axiosLib from 'axios';
import { render } from 'preact';

import 'moment';
import 'moment/locale/ru';
import 'moment/locale/en-gb';

import config from './config.json';
import './assets/global-styles.scss';
import './assets/global-mui-overrides.scss';

function initApp() {
  import('./App')
    // eslint-disable-next-line react/jsx-pascal-case
    .then((Module) => { render(<Module.default />, document.body); });
}

if (process.env.PREACT_APP_ENV === 'development') {
  config.REACT_APP_TARIFF = process.env.PREACT_APP_TARIFF;
  config.REACT_APP_TELEGRAM_LINK = process.env.PREACT_APP_TELEGRAM_LINK;
  config.REACT_APP_API_BACKEND_BASEPATH = process.env.PREACT_APP_API_BACKEND_BASEPATH;
  config.REACT_APP_PRODUCTS = JSON.parse(process.env.PREACT_APP_PRODUCTS || '{}');
  config.REACT_APP_CURRENCY_CODE = JSON.parse(process.env.PREACT_APP_CURRENCY_CODE);
  initApp();
} else {
  axiosLib.get('/config.json')
    .then((result) => {
      if (!result.data || !result.data.REACT_APP_API_BACKEND_BASEPATH) {
        throw Error('value for REACT_APP_API_BACKEND_BASEPATH not found !');
      }
      config.REACT_APP_TARIFF = result.data.REACT_APP_TARIFF;
      config.REACT_APP_TELEGRAM_LINK = result.data.REACT_APP_TELEGRAM_LINK;
      config.REACT_APP_API_BACKEND_BASEPATH = result.data.REACT_APP_API_BACKEND_BASEPATH;
      config.REACT_APP_PRODUCTS = result.data.REACT_APP_PRODUCTS;
      config.REACT_APP_CURRENCY_CODE = result.data.REACT_APP_CURRENCY_CODE;
      initApp();
    });
}
